import React, { useState, useEffect } from "react";
import "./ResetPass.css";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { TextField, Button, Icon, Box } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { useLocation } from "react-router";
import axios from "axios";
import { PORTAL_URL, ROOT_URL } from "../utils/urls";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      justifyContent: "center",
    },
    paper: {
      marginTop: theme.spacing(5),
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    item: {
      padding: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(1),
    },
    icon: {
      color: "#fff",
      borderRadius: "50%",
      background: "#ccc",
      padding: ".5rem",
    },
  })
);

export default function ResetPass() {
  const classes = useStyles();
  const location = useLocation();
  const [pass, setPass] = useState<string>("");
  const [passConfirm, setPassConfirm] = useState<string>("");
  const [passError, setPassError] = useState<string | null>(null);
  const [passConfirmError, setPassConfirmError] = useState<string | null>(null);
  const [privateCode, setPrivateCode] = useState<string>("");
  const [alert, setAlert] = useState<string | null>(null);

  useEffect(() => {
    const resetPassUrlMatch = ROOT_URL.match(location);
    let code = resetPassUrlMatch.isMatched && resetPassUrlMatch?.query?.code;

    setPrivateCode(code || "");
  }, [location]);

  useEffect(() => {
    if (!alert) {
      return;
    }

    const timer = setTimeout(() => {
      setAlert(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [alert]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const validation = validatePass(pass) && validatePassConfirm(passConfirm);
      if (!validation) {
        return;
      }

      if (!privateCode) {
        setAlert("Token code is incorrect!");

        return;
      }

      await axios.post("https://connect-api.gocare.io/auth/reset-password", {
        code: privateCode,
        password: pass,
        passwordConfirmation: passConfirm,
      });

      window.location.href = PORTAL_URL;
    } catch (error) {
      console.log("An error occurred:", error?.response);

      const errMsg: string[] = error?.response?.data?.message?.[0]?.messages?.map(
        (m: any) => m.message
      );

      setAlert(errMsg?.join("; ") || null);
    }
  };

  const validatePass = (pass: string) => {
    if (!pass.match(/[A-Z]/)?.length) {
      setPassError("Password should contain at least one uppercase character");
      return false;
    }

    if (!pass.match(/[a-z]/)?.length) {
      setPassError("Password should contain at least one lowercase character");
      return false;
    }

    if (!pass.match(/[0-9]/)?.length) {
      setPassError("Password should contain at least one digit");
      return false;
    }

    // eslint-disable-next-line
    if (!pass.match(/[\*\.!@#$%^&(){}[\]:;<>,?\/~_+\-=\|]/)?.length) {
      setPassError("Password should contain at least one special character");
      return false;
    }

    if (pass.length < 8 || pass.length > 32) {
      setPassError("Password should be at least 8 characters, but no more than 32");
      return false;
    }

    setPassError(null);
    return true;
  };

  const validatePassConfirm = (passConfirm: string) => {
    if (pass !== passConfirm) {
      setPassConfirmError("Passwords don't match");
      return false;
    }

    setPassConfirmError(null);
    return true;
  };

  return (
    <div className={classes.root}>
      {alert && <Alert severity="error">{alert}</Alert>}
      <Grid container justify="center">
        <Grid item xs={8} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Grid container>
              <form
                className={classes.root}
                autoComplete="off"
                onSubmit={onSubmit}
              >
                <Grid item xs={12} className={classes.item}>
                  <Icon aria-label={`password icon`} className={classes.icon}>
                    <LockIcon />
                  </Icon>
                </Grid>
                <Grid item xs={12} className={classes.item}>
                  <TextField
                    fullWidth
                    id="newPassword"
                    label="New password"
                    required
                    type="password"
                    value={pass}
                    error={!!passError}
                    helperText={passError}
                    onChange={(e) => {
                      setPass(e.currentTarget.value);
                      validatePass(e.currentTarget.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.item}>
                  <TextField
                    fullWidth
                    id="confirmNewPassword"
                    label="Confirm New password"
                    type="password"
                    value={passConfirm}
                    error={!!passConfirmError}
                    helperText={passConfirmError}
                    onChange={(e) => {
                      setPassConfirm(e.currentTarget.value);
                      setPassConfirmError(null);
                    }}
                    required
                  />
                </Grid>
                <Box display="none">
                  <Grid item xs={12} className={classes.item}>
                    <TextField
                      fullWidth
                      id="privateCode"
                      type="password"
                      label="privateCode"
                      value={privateCode}
                      disabled
                      hidden={true}
                    />
                  </Grid>
                </Box>

                <Grid item xs={12} className={classes.item}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
